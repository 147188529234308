import { Component, AfterViewInit, OnInit } from "@angular/core";
import * as L from "leaflet";
import "leaflet.zoomhome";
import "leaflet-search";

// import "leaflet-easybutton"
// import "leaflet.zoomhome"

import { MunicipiosConveniadosService } from "./mapa-municipios-conveniados.service";
import { ErrorService } from "../services/error.service";

@Component({
  selector: "app-mapa-municipios-conveniados",
  templateUrl: "./mapa-municipios-conveniados.component.html",
  styleUrls: ["./mapa-municipios-conveniados.component.scss"],
})
export class MapaMunicipiosConveniadosComponent implements OnInit {
  public map;
  public states;
  public municipiosForJSON;
  public color;
  public fillColor;
  public weight;
  public opacity;
  public fillOpacity;
  public stateLayer;
  public showOverlay = true;
  options: any = {};
  municipiosForAPI: Array<any> = [];
  municipiosUnificada: Array<any> = [];

  minZoom;
  maxZoom;
  windowSize = screen.width;

  public info = new L.Control({ position: "topleft" });
  public infoQTD = new L.Control({ position: "bottomleft" });


  constructor(
    public municipiosConveniadosService: MunicipiosConveniadosService,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {

    this.matchMedia();
    this.getMunicipiosForJSON();
    this.getMunicipiosForAPI();

    setTimeout(() => {
      setTimeout(() => {
        this.showOverlay = false;
      }, 1500);
      if(this.municipiosForAPI.length != 0 && this.municipiosForJSON.length != 0){
        this.loadInfoMunicipios();
      }
    }, 1600);
  }

  matchMedia(){
    if (this.windowSize <= 1366 ) {
      this.minZoom = 7.2;
      this.maxZoom = 7.2;
    }  else if (this.windowSize >= 1367 && this.windowSize <= 1600) {
      this.minZoom = 7.3;
      this.maxZoom = 7.3;
    } else if (this.windowSize >= 1601 && this.windowSize <= 1680) {
      this.minZoom = 7.4;
      this.maxZoom = 7.4;
    } else if (this.windowSize >= 1681 && this.windowSize <= 1920){
      this.minZoom = 7.8;
      this.maxZoom = 7.8;
    }
  }

  public initMap(): void {
    var southWest = L.latLng(-34.5, -58.5),
      northEast = L.latLng(-12.5, -44.0),
      mybounds = L.latLngBounds(southWest, northEast);

    this.map = L.map("map", {
      zoomControl: false,
      center: [ -22.7, -39.5 ],
      zoom: 8.2,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      reuseTiles: true,
      unloadInvisibleTiles: true,
      doubleClickZoom: false,
      scrollWheelZoom: 'center',
      zoomSnap: false,
      zoomDelta: false,
      trackResize: false,
      centerFixed: [-22.7, -39.5],
      toggleDisplay: true,
      zoomLevelFixed: true,
      tap: false,
      touchZoom: false,
      dragging:false,
      boxZoom: false,
    })
      .setView([-22.7, -39.5], this.minZoom)
      .setMaxBounds(mybounds);

    this.map.attributionControl.setPrefix("");

  }

  public getStyleProps(balcaoUnico, municipioConveniado, viabilidadeAutomatica) {
    if (municipioConveniado === true && viabilidadeAutomatica === false && balcaoUnico === false) {
      this.color = "#00bdc3";
      this.fillColor = "#00a5aa";
      this.weight = 1.2;
      // this.opacity = 1;
      // this.fillOpacity = 0.4;
      return;
    } else if (municipioConveniado === false) {
      this.color = "#f6766a";
      this.fillColor = "#d2645a";
      this.weight = 1;
      // this.opacity = 0.4;
      // this.fillOpacity = 0.4;
      return;
    } else if (municipioConveniado === false || municipioConveniado === true && (viabilidadeAutomatica === true && balcaoUnico === false)) {
      this.color = "#fcfd01";
      this.fillColor = "#dbdc03";
      this.weight = 1;
      // this.opacity = 0.4;
      // this.fillOpacity = 0.4;
      return;
    } else if (municipioConveniado === true && viabilidadeAutomatica === true && balcaoUnico === true) {
      this.color = "#101b48";
      this.fillColor = "#0a112e";
      this.weight = 1;
      // this.opacity = 0.4;
      // this.fillOpacity = 0.4;
      return;
    } else {
      this.color = "#999999";
      this.fillColor = "#d0d0d0";
      this.weight = 1;
      this.opacity = 0.4;
      this.fillOpacity = 0.4;
      return;
    }
  }

  public highlightFeature(e) {
    const layer = e.target;

    if (layer.feature.municipioConveniado === true && layer.feature.viabilidadeAutomatica === false && layer.feature.balcaoUnico === false) {
      layer.setStyle({
        color: "#00bdc3",
        fillColor: "#00a5aa",
        weight: 1.4,
        // opacity: 0,
        // fillOpacity: 0,
      });
      if (!L.Browser.ie && !L.Browser.opera) {
        layer.bringToFront();
      }
      this.info.update(layer.feature);
      return;
    } else if (layer.feature.municipioConveniado === false) {
      layer.setStyle({
        color: "#f6766a",
        fillColor: "#d2645a",
        weight: 1.4,
        // opacity: 0,
        // fillOpacity: 0,
      });
      if (!L.Browser.ie && !L.Browser.opera) {
        layer.bringToFront();
      }
      this.info.update(layer.feature);
      return;
    } else if (layer.feature.municipioConveniado === false || layer.feature.municipioConveniado === true && (layer.feature.viabilidadeAutomatica === true && layer.feature.balcaoUnico === false)) {
      layer.setStyle({
        color: "#fcfd01",
        fillColor: "#dbdc03",
        weight: 1.4,
        // opacity: 0,
        // fillOpacity: 0,
      });
      if (!L.Browser.ie && !L.Browser.opera) {
        layer.bringToFront();
      }
      this.info.update(layer.feature);
      return;
    } else if (layer.feature.municipioConveniado === true && layer.feature.viabilidadeAutomatica === true && layer.feature.balcaoUnico === true) {
      layer.setStyle({
        color: "#101b48",
        fillColor: "#0a112e",
        weight: 1.4,
        // opacity: 0,
        // fillOpacity: 0,
      });
      if (!L.Browser.ie && !L.Browser.opera) {
        layer.bringToFront();
      }
      this.info.update(layer.feature);
      return;
    }

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    this.info.update(layer.feature);
  }

  public resetFeature(e) {
    this.info.update();
  }

  public zoomToFeature(e) {
    this.map.fitBounds(e.target.getBounds());
  }

  private initStatesLayer(statesLenght) {

    if(statesLenght){

      this.stateLayer = L.geoJSON(this.states, {

        style: (feature) => ({
          getStyleProps: this.getStyleProps(feature.balcaoUnico, feature.municipioConveniado, feature.viabilidadeAutomatica),
          weight: this.weight,
          opacity: this.opacity,
          fillOpacity: this.fillOpacity,
          fillColor: this.fillColor,
          color: this.color,
        }),
        onEachFeature: (feature, marker) =>
          marker.bindPopup(
            `<h6 class="mt-2 d-block">${feature.descricao}</h6>` +
            `<span class="mt-2 d-block"><b>Integrado a REDESIM: </b>${(feature.municipioConveniado == true ? "Sim" : "Não")}` +
            `<span class="mt-2 d-block"><b>Viabilidade Automática: </b>${(feature.viabilidadeAutomatica == true ? "Sim" : "Não")}` +
            `<span class="mt-2 d-block"><b>Balcão Único: </b>${(feature.balcaoUnico == true ? "Sim" : "Não")}`
          )
          .on({
            mouseover: (e) => this.highlightFeature(e),
            mouseout: (e) => this.resetFeature(e),
            //click: this.zoomToFeature,
          })
      });

      this.map.addLayer(this.stateLayer);
      //this.map.addLayer(this.markers);

      this.infoQTD.onAdd = function (map) {
        this._div = L.DomUtil.create('div', 'infoQTD');
        this.update();
        return this._div;
      };

      this.infoQTD.update = function (props) {
        this._div.innerHTML = `<h1>504</h1>` +
        `<h4>Municípios Conveniados</h4>`
      };

      this.info.onAdd = function (map) {
        this._div = L.DomUtil.create(`div`, "boxLegendaInfo");
        this.update();
        return this._div;
      };

      this.info.update = function (feature) {
        this._div.innerHTML =
          `<h6>Informações de Municípios Paulistas</h6>` +
          `<span class="badge badge-primary badge-Conveniados py-1 px-2 mr-1">&nbsp;&nbsp;</span>Integrados a REDESIM</br>
          <span class="badge badge-primary badge-naoConveniados py-1 px-2 mr-1">&nbsp;&nbsp;</span>Não Integrados</br>
          <span class="badge badge-primary badge-Viabialidade py-1 px-2 mr-1">&nbsp;&nbsp;</span>Viabilidade Automática</br>
          <span class="badge badge-secondary badge-BU py-1 px-2 mr-1">&nbsp;&nbsp;</span>Balcão Único</br></br>` +
          (feature
            ?
              `<h5 class="mt-2 d-block">${feature.descricao}</h5>` +
              `<span class="mt-2 d-block"><b>Integrado a REDESIM: </b>${(feature.municipioConveniado == true ? "Sim" : "Não")}` +
              `<span class="mt-2 d-block"><b>Viabilidade Automática: </b>${(feature.viabilidadeAutomatica == true ? "Sim" : "Não")}` +
              `<span class="mt-2 d-block"><b>Balcão Único: </b>${(feature.balcaoUnico == true ? "Sim" : "Não")}` +
              "</br>"
            : '<span class="mt-2 d-block">Passe o mouse para mais informações</span>');
      };

      var univ = L.geoJSON(this.states, {
        onEachFeature: function(feature, layer) {
          layer.feature.properties.searchItem = layer.feature.descricao;
        }
      });

      var searchControl = new L.Control.Search({
        layer: this.stateLayer,
        initial: false,
        minLength: 3,
        textPlaceholder: "Pesquisar",
        propertyName: 'searchItem',
        position: "topright",
        scrollWheelZoom: 'center',
        autoType: true,
        tipAutoSubmit: true,
        firstTipSubmit: true,
        scrollZoom: false,
        dragging:false,
        marker: false,
        propertyLoc: ['lat','lon'],
        moveToLocation: function(latlng, title, map) {
          // map.fitBounds( latlng.layer.getBounds() );
          // var zoom = map.getBoundsZoom(latlng.layer.getBounds());
          // map.setView(latlng, zoom); // access the zoom
          map.setView(latlng, 7.2);
        }
      });

      searchControl.on('search:locationfound', function(e) {

        //console.log('search:locationfound', );

        //map.removeLayer(this._markerSearch)

        //e.layer.setStyle({weight: 2.2});

        if(e.layer._popup)
          e.layer.openPopup();

        L.DomEvent.disableScrollPropagation(document.getElementById('map'))


      }).on('search:collapsed', function(e) {
        this.stateLayer.eachLayer(function(layer) {	//restore feature color
          this.stateLayer.resetStyle(layer);
        });
      });

      L.Control.zoomHome({
        position: 'topright',
        zoomHomeTitle: 'Recentralizar',
        zoomHomeIcon: 'fas fa-arrows-alt'
      }).addTo(this.map);

      //this.stateLayer.remove();
      //this.stateLayer.bringToFront();

      this.info.addTo(this.map);
      this.infoQTD.addTo(this.map);
      this.map.addLayer(this.stateLayer);
      this.map.addControl( searchControl );

      this.map.dragging.disable();
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
      //this.map.scrollWheelPan.disable();

      this.map.boxZoom.disable();
      this.map.keyboard.disable();
      if (this.map.tap) this.map.tap.disable();
      document.getElementById('map').style.cursor='default';

    }else{
      this.getMunicipiosForJSON();
      this.getMunicipiosForAPI();
    }

  }

  getMunicipiosForAPI(){
    this.municipiosConveniadosService.getListarMunicipio()
    .then(res => {
      this.municipiosForAPI = res;
    })
    .catch((erro) => {
      this.errorService.error(erro);
    });
  }

  getMunicipiosForJSON(){
    this.municipiosConveniadosService.getStateShapes().subscribe((municipiosForJSON) => {
      this.municipiosForJSON = municipiosForJSON;
    });
  }


  loadInfoMunicipios(){

    let statesLenght;
    //this.states = this.municipiosForJSON;

    //this.states = this.municipiosForJSON;
    let initialStates = this.municipiosForJSON;
    // this.states = states;

    let newArray = initialStates.features.map(item => {
      return item
    });

      this.states = newArray.map((item) => {
        const matchedObject = this.municipiosForAPI.find((obj) => obj.municipioIbge == item.properties.id);
        return { ...item, ...matchedObject };
      });

      if(this.states.length > 0) {
        statesLenght = true;
      }


    this.initMap();
    this.initStatesLayer(statesLenght);
  }

}
